import React,{useState,useEffect} from 'react'
import "./css/Infomercial.less"
import store from '../store'
export default function Infomercial() {
  const state = store.getState()
  const [gif,setGif]=useState("")
  useEffect(() => {
    setGif(store.getState().changeGif)

    // console.log('监听中..',store.getState().changeGif,)
    // 监听state的变化
    let unsubscribe  = store.subscribe(() => {
      console.log('监听中..',store.getState().changeGif,)
      setGif(store.getState().changeGif)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  },[])



  return (
 <div className='Infomercial'>
<div>
<img className='img1' src={gif} alt="" />
<img className='img2' src={require('../assets/img/advertisement/advertisement.png')} alt="" />
  <img className='img2' src={require('../assets/img/advertisement/advertisement1.jpg')} alt="" />
  <img  className='img2' src={require('../assets/img/advertisement/advertisement2.jpg')} alt="" />
  <img className='img2' src={require('../assets/img/advertisement/ad4.png')} alt="" />
</div>
 </div>
  )
}
