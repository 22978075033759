import React, { useState, useEffect, useRef } from 'react'
import logoImg from '../assets/img/logo.png'
import default_img from '../assets/img/moren.png'
import logo from '../assets/img/logo-cg.png'
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Space, message, Input } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { logout, msglist } from '../request/api'
import bus from "./bus"
import Modal from './Modalcopy'
import './css/header.less'
import store from '../store'

import CountUp from 'react-countup';

export default function Header() {
  const state = store.getState()
  let [dialog, setDialog] = useState(false)
  function closemodel() {
    console.log("父组件的关闭弹出框");
    setDialog(false)


  }
  // 滚动相关js
  const [isScrolle, setIsScrolle] = useState(true);

  // 滚动速度，值越小，滚动越快
  const speed = 5000;
  const warper = useRef();
  const childDom1 = useRef();
  // const childDom2 = useRef();
  // 开始滚动
  useEffect(() => {
    // 多拷贝一层，让它无缝滚动
    // childDom2.current.innerHTML = childDom1.current.innerHTML;
    let timer;
    if (isScrolle) {
      timer = setInterval(
        () =>
          warper.current.scrollTop + 40 >= childDom1.current.scrollHeight
            ? (warper.current.scrollTop = 0)
            : warper.current.scrollTop += 40,
        speed
      );
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isScrolle]);

  const hoverHandler = (flag) => setIsScrolle(flag);

  // 
  useEffect(() => {



    // 监听state的变化
    let unsubscribe = store.subscribe(() => {
      // console.log('监听中..',store.getState().inputVal,)
      setusername(store.getState().inputVal)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  useEffect(() => {


    // 监听state的变化
    let unsubscribe2 = store.subscribe(() => {
      // console.log('监听中..',store.getState().changeCredit,)
      setcredits(Number(store.getState().changeCredit).toFixed(2))
    })
    return () => {
      // 取消监听
      unsubscribe2();
    }
  }, [])
  useEffect(() => {

    // let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    // // total_credits

    // setcredits(gamer_reg.total_credits)

    // 监听state的变化
    let unsubscribe3 = store.subscribe(() => {
      // console.log('监听中..',store.getState().changeAvatar,"这是头像1111111111111111111111111111111111")
      setAvatar(store.getState().changeAvatar)
    })
    return () => {
      // 取消监听
      unsubscribe3();
    }
  }, [])
  const { Search } = Input;
  const navigate = useNavigate()
  const [defaultImg, setdefaultImg] = useState(default_img)
  const [username, setusername] = useState(state.inputVal)
  const [credits, setcredits] = useState(0)
  const [oldcredits, setoldcredits] = useState(0)
  const [avatar, setAvatar] = useState(null)
  const [message, setMessage] = useState([])
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  // 相当于vue生命周期中mounted
  // useEffect(() => {
  //   // console.log("用户名",username);
  //   if(localStorage.hasOwnProperty("gamer_reg")==false){
  //     return
  //   }
  //   bus.on('sendVal',data=>{
  //     // 
  //     let obj= JSON.stringify(data)


  //     setusername(JSON.parse(obj))
  //     console.log(JSON.parse(obj));
  //     console.log("用户名",username);
  //   })
  //    let username1=localStorage.getItem('username')
  //    if(username1){
  //     let obj= JSON.stringify(username1)


  //     setusername(JSON.parse(obj))
  //     console.log("用户名",username);
  //    }

  // }, []);


  useEffect(() => {
    // console.log("用户名",username);
    setTimeout(() => {

      
   
      if (localStorage.hasOwnProperty("gamer_reg") == false) {
        return
      } else {

      }
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))


      let oldcreditsobj = JSON.parse(localStorage.getItem("credits"))
      if(oldcreditsobj.change==true){
        setoldcredits(gamer_reg.last_total_credits)
        let credits={
          change:false,
        
         }
                  localStorage.setItem("credits", JSON.stringify(credits));
      }else{
        setoldcredits(gamer_reg.total_credits)
      }


      let obj = {
        uuid: gamer_reg.uuid
      }
      msglist(obj).then((res) => {
        console.log(res);
        let list = []
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].type == "new_release") {

            for (let k = 0; k < res.data[i].param.length; k++) {
              let objli = {
                message: res.data[i].message + "" + res.data[i].param[k].gname,
                click: res.data[i].param[k].gid
              }
              list.push(objli)
            }
          } else {
            let objli = {
              message: res.data[i].message,
              click: "guest"
            }
            list.push(objli)
          }
        }
        setMessage(list)
        console.log(list);
      })
    }, 500)


  }, []);
  // 退出登录
  const onClick = ({ key }) => {

    if (key === "1") {

      quit()
    }
  };
  const quit = () => {
    // 清除localStorage中所有东西
    let UT = JSON.parse(localStorage.getItem("utoken"))
    // let Gid=JSON.parse(localStorage.getItem("gid"))
    localStorage.clear()
    logout({
      utoken: UT
    }).then((res) => {
      console.log("退出成功");

      window.location.reload()
      //   setTimeout(()=>{
      //     navigate('/'+Gid)
      // },500)
    })

    // message.success("退出成功，即将返回首页");


  }

  const items = [
    // {
    //   key: '1',
    //   label: '修改资料',
    // },

    {
      key: '1',
      label: 'Logout',
      icon: <LogoutOutlined />,
    },
  ];
  const changePush = (val) => {
    console.log(val);
    if (val == "guest") {
      setDialog(true)
    }

  }
  const getlist = () => {
    console.log("更新msg");
    if (localStorage.hasOwnProperty("gamer_reg") == false) {
      return
    } else {

    }
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))


    let oldcreditsobj = JSON.parse(localStorage.getItem("credits"))
    if(oldcreditsobj.change==true){
      setoldcredits(gamer_reg.last_total_credits)
      let credits={
        change:false,
      
       }
                localStorage.setItem("credits", JSON.stringify(credits));
    }else{
      setoldcredits(gamer_reg.total_credits)
    }


    let obj = {
      uuid: gamer_reg.uuid
    }
    msglist(obj).then((res) => {
      console.log(res);
      let list = []
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].type == "new_release") {

          for (let k = 0; k < res.data[i].param.length; k++) {
            let objli = {
              message: res.data[i].message + "" + res.data[i].param[k].gname,
              click: res.data[i].param[k].gid
            }
            list.push(objli)
          }
        } else {
          let objli = {
            message: res.data[i].message,
            click: "guest"
          }
          list.push(objli)
        }
      }
      setMessage(list)
      console.log(list);
    })

  }

  return (
    <div>
      <header className='header'>
        <div className='logo'>
          {/* <Link to="/home">    <div>  <img src={logo} alt="" /><span>Game</span>Cheers</div></Link> */}

          <div>  <img src={logo} alt="" /><span> Cheer</span>Game</div>
        </div>
        {/* <p>{username}111</p> */}

        {/* <div className='search'>
                <Search
                size="large"
      addonBefore="what"
      placeholder="Ex:2048"
      allowClear
      onSearch={onSearch}
      style={{
        width: 550,
      }}
    />
                </div> */}

        {/*  */}

        <div className='Release parent' ref={warper}>
          {/* <ul>
                    <li style={{color:"green"}}>New Release:</li>
                    <li>Num</li>
                    <li>SnakeStar</li>
                    <li>Matchword</li>
                    <li>Search2048</li>
                  </ul> */}
          {/* <span>{message}</span> */}
          <ul className='child' ref={childDom1}>
            {message.map((item, idx) => (

              <li key={idx} onMouseOver={() => hoverHandler(false)}
                onMouseLeave={() => hoverHandler(true)}>

                <span className='modelmessage'>{item.message}</span>
                <span style={{ display: item.click == "guest" ? 'block' : 'none' }} className='modelspan' onClick={() => { changePush(item.click) }}>Click here</span>
              </li>


            ))
            }

          </ul>
          {/* <div className='child' ref={childDom2}></div> */}
          {/*  */}

          {/*  */}
        </div>

        <div className='userright'>
          <span className='credits'> <span style={{ color: "#6d6d6d", fontSize: "16px" }}>Total Credits:&nbsp;</span>
          
          {/* {credits} */}

                    <CountUp
                        key={1000}
                        // 动画结束值
                        end={credits}
                        // 保留一位小数
                        decimals={1}
                        // 动画持续时间
                        duration={3}
                        // 动画延迟时间
                        delay={1}
                        // 千位分隔符
                        separator=','
                        // 数据初始值
                        start={oldcredits}
               
                        // 动画结束回调
                        onEnd={() => {
                            console.log('动画结束');
                        }}
                        // 动画开始回调
                        onStart={() => {
                            console.log('动画开始');
                        }}
></CountUp>
          <img src={require('../assets/img/credits.png')} alt="" /></span>
          {/* <div style={{ display: username !== "null" ? 'block' : 'none' }}> */}
            <div style={{ display: username.substring(0,5)!=="guest" ? 'block' : 'none' }}>
            <Dropdown
              menu={{
                items,
                onClick,
              }}
              trigger={['click']}
              overlayStyle={{ minWidth: 180 }}
              placement="bottom"
            >

              <a onClick={(e) => e.preventDefault()} style={{ display: username !== "null" ? 'block' : 'none' }}>
                <Space>

                  <div className='user' style={{ display: username !== "null" ? 'block' : 'none' }}>  Hi, {username}
                   <div className='icon' style={{ display: avatar !== null ? 'block' : 'none' }}><img className='user_avatar' src={avatar} alt="" /></div> 
                  <div className='icon' style={{ display: avatar !== null ? 'none' : 'block' }} >  <UserOutlined /></div></div>
                  <div className='user' style={{ display: username == "null" ? 'block' : 'none' }}><div className='icon'>  <UserOutlined /></div></div>
                  {/* <DownOutlined /> */}
                </Space>
              </a>

            </Dropdown>
          </div>
          {/* <div style={{ display: username == "null" ? 'block' : 'none' }}> */}
          <div style={{ display: username.substring(0,5)=="guest"? 'block' : 'none' }}>
          <div className='user' style={{ display: username !== "null" ? 'block' : 'none' }}>  Hi, {username}
                   <div className='icon' style={{ display: avatar !== null ? 'block' : 'none' }}><img className='user_avatar' src={avatar} alt="" /></div> 
                  <div className='icon' style={{ display: avatar !== null ? 'none' : 'block' }} >  <UserOutlined /></div></div>
                  <div className='user' style={{ display: username == "null" ? 'block' : 'none' }}><div className='icon'>  <UserOutlined /></div></div>
                  {/* <DownOutlined /> */}
          </div>
          <div style={{ display: username==null? 'block' : 'none' }}>
            <div className='user' style={{ display: username !== "null" ? 'block' : 'none' }}>Hi, {username} <img src={avatar} alt="" /><div className='icon'>  <UserOutlined /></div></div>
            <div className='user' style={{ display: username == "null" ? 'block' : 'none' }}><div className='icon'>  <UserOutlined /></div></div>
          </div>
        </div>


      </header>
      <Modal
        xdata={dialog}
        setPare={closemodel}


getlist={getlist}
      ></Modal>
    </div>

    // <Modal
    // xdata={dialog}
    // setPare={closemodel}



    // ></Modal>
  )
}
