// 定义默认状态值（即默认数据）
const defaultState = {
    // input的文字
    inputVal: "null",
    // 列表项数组
    changeCredit: 0,
    changeAvatar: "",
    changeAvatarid: -1,
    changeGif: "",
    changeGifid: -1,
    cheernum: 0
}

// 导出一个函数，用于返回state
export default (state = defaultState, action) => {
    // console.log(state, action, "对传入进来的值进行打印") // 对传入进来的值进行打印
    if (action.type === "changeInputValue") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.inputVal = action.value; // 重新赋值action过来的value
        // console.log(newState);
        return newState;
    } else if (action.type === "changeCredits") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.changeCredit = action.value; // 重新赋值action过来的value
        // console.log(newState, "购买后积分情况");
        return newState;
    } else if (action.type === "changeAvatar") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.changeAvatar = action.value; // 重新赋值action过来的value
        // console.log(newState);
        return newState;
    } else if (action.type === "changeAvatarid") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.changeAvatarid = action.value; // 重新赋值action过来的value
        // console.log(newState);
        return newState;
    } else if (action.type === "changeGif") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.changeGif = action.value; // 重新赋值action过来的value
        console.log(newState.changeGif, "图片");
        return newState;
    } else if (action.type === "changeGifid") {
        let newState = {...state }; // 对原本的state做一次深拷贝
        newState.changeGifid = action.value; // 重新赋值action过来的value
        // console.log(newState);
        return newState;
    } else if (action.type === "cheernum") {
        if (action.show === true) {
            console.log("更改changenum了");

            let newState = {...state }; // 对原本的state做一次深拷贝
            newState.cheernum = action.value; // 重新赋值action过来的value
            // console.log(newState);
            return newState;
        }

    }
    return state;
}